import React from "react";
import Body from "./body";
import Services from "../blocks/services";
import Contact from "../blocks/contact";
import "./page-full.scss"
import ServicesCoaching from "../blocks/services-coaching";

const PageFull = (props) => {

  const componentPagesIds = [24, 6];

  const getContentClass = componentPagesIds.includes(Number(props.entity.entityId)) ? 'has-component' : 'no-component';

  return (
    <div className={`page-full ${getContentClass}`}>
      <div className="page-content-wrapper">
        <h1>{props.entity.entityLabel}</h1>
        <div className="page-content">
          {props.entity.entityId === '24' &&
          <div className="component">
            <Services/>
          </div>
          }
          {props.entity.entityId === '111' &&
          <div className="component">
            <ServicesCoaching/>
          </div>
          }
          <Body className="page-body" html={props.entity.body?.value} />
          {props.entity.entityId === '6' &&
          <div className="component">
            <Contact/>
          </div>
          }
        </div>
      </div>
    </div>
  )
};

export default PageFull;
