import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import PageFull from "../components/node/page-full";

const Page = ({ data }) => {

  const entityData = data.drupal.nodeById?.entityTranslation;

  if (!entityData) {
    return (
        <Layout></Layout>
    )
  }

  return (
    <Layout entity={entityData}>
      <PageFull entity={entityData} />
    </Layout>
  )
}

export default Page;

export const query = graphql`
  query($id: String!, $languageId: Drupal_LanguageId!) {
    drupal {
      nodeById(id: $id) {
        ... on Drupal_NodePage {
          entityTranslation(language: $languageId) {
            ...PageFull
          }
        }
      }
    }
  }
`;
