import React, {useContext} from "react";
import "./services-coaching.scss"
import Ankeiler from "./ankeiler";
import {SiteContext} from "../../context/site-context";

const ServicesCoaching = (props) => {

  const siteContext = useContext(SiteContext);
  const i18next = siteContext.i18next;

  const { showTitle, padding } = props;
  const paddingClass = !padding ? 'no-padding' : '';

  if (i18next.language ==='nl') {
    return (
      <div className={`services-coaching ${paddingClass}`}>
        <div className="inner-content">
          { showTitle &&
            <h2>Coaching</h2>
          }
          <Ankeiler
            icon="puzzlehead"
            style="horizontal"
            title="Introvert Coaching"
            subtitle="De kracht van de introvert"
            link="/nl/introvert-coaching-de-kracht-van-de-introvert"
            cta="Introvert coaching">Wil je leren hoe je jouw kracht als introvert beter kunt benutten? Heb je het gevoel dat er zoveel meer in je zit, maar durf je nu niet de stappen te zetten om het er echt uit te krijgen? En wil je leren hoe je dat kritische stemmetje in je hoofd kunt stoppen, dat je maar blijft vertellen dat je het niet kunt?</Ankeiler>
          <Ankeiler
            icon="finding"
            style="horizontal"
            title="Loopbaancoaching"
            subtitle="Introverte kracht aan het werk"
            link="/nl/loopbaancoaching-introverte-kracht-aan-het-werk"
            cta="Loopbaancoaching">Wil je weten wat jouw drijfveren en talenten zijn? En ben je benieuwd hoe anderen die zien? Dat is de kern van mijn loopbaantraject Introverte Kracht aan het Werk. In dit traject is er ook uitgebreid aandacht voor introversie en de kracht daarvan. Je gaat op zoek naar jouw unieke kracht en hoe je die nog beter naar voren kunt brengen. Daarnaast leer je hoe je om kunt gaan met specifieke introverte uitdagingen.</Ankeiler>
          <Ankeiler
            icon="coaching"
            style="horizontal"
            title="Introvert leiderschap"
            subtitle="De kracht van de introverte leider"
            link="/nl/coachtraject-introvert-leiderschap"
            cta="Introvert leiderschap">Ben jij een introverte leider of heb je ambitie om leiding te geven als introvert? Dan is mijn leiderschapstraject iets voor jou. In dit traject leer je alles over jouw unieke kracht en over hoe je om kunt gaan met uitdagingen.</Ankeiler>
        </div>
      </div>
    )
  }

  if (i18next.language ==='en') {
    return (
      <div className={`services-coaching ${paddingClass}`}>
      </div>
    )
  }

};

export default ServicesCoaching;
