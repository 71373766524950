import React, {useContext} from "react";
import "./services.scss"
import Ankeiler from "./ankeiler";
import {SiteContext} from "../../context/site-context";

const Contact = (props) => {

  const siteContext = useContext(SiteContext);
  const i18next = siteContext.i18next;

  const { showTitle, padding } = props;
  const paddingClass = !padding ? 'no-padding' : '';

  if (i18next.language ==='nl') {
    return (
      <div className={`services ${paddingClass}`}>
        <div className="inner-content">
          <Ankeiler type="email" icon="email" style="simple" title="E-mail" link="mailto:info@quietquality.nl">info@quietquality.nl</Ankeiler>
          <Ankeiler icon="handshake" target="_blank" style="simple" title="Plan een vrijblijvend gesprek" link="https://booking.setmore.com/scheduleappointment/40d4bd8f-1787-4d9e-9d45-8ca74f4886a5/services"></Ankeiler>
          <Ankeiler icon="calendar" target="_blank" style="simple" title="Plan een coachsessie" link="https://booking.setmore.com/scheduleappointment/40d4bd8f-1787-4d9e-9d45-8ca74f4886a5/services">Of bekijk andere diensten</Ankeiler>
        </div>
      </div>
    )
  }

  if (i18next.language ==='en') {
    return (
      <div className={`services ${paddingClass}`}>
        <div className="inner-content">
          { showTitle &&
            <h2>How can I help you?</h2>
          }
          <Ankeiler icon="coaching" title="Coaching" link="/en/services" cta="More on coaching">Learn to use your full potential in my Put Your Strength Into Action program.</Ankeiler>
          <Ankeiler icon="training" title="Training" link="/en/services" cta="In-company training">Create more mutual understanding by following one of my sessions on introversion and diversity.</Ankeiler>
          <Ankeiler icon="speaking" title="Speaking" link="/en/services" cta="More on speaking">I'm available to speak during company sessions, conferences and events.</Ankeiler>
        </div>
      </div>
    )
  }

};

export default Contact;
